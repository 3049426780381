module.exports = {
  APP_PROTOCOL: 'https',
  BLOG_SECTION_ID: 'blog',
  BLOG_POST_PAGE_SECTION_ID: 'post',
  MEMBERS_APP_ID: '14ad9202-3dd3-128a-57bd-e5675fd7e313',
  FORUM_APP_ID: '14724f35-6794-cd1a-0244-25fd138f9242',
  FORUM_SECTION_ID: 'forum',
  SANTA_MEMBERS_APP_ID: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
  NOTIFICATIONS_APP_ID: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
  NOTIFICATIONS_SETTING_APP_ID: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
  MEMBER_INFO_APP_ID: '14cffd81-5215-0a7f-22f8-074b0e2401fb',
  MEMBER_INFO_SECTION_ID: 'member_info',
  FOLLOWERS_APP_ID: '14ebe801-d78a-daa9-c9e5-0286a891e46f',
  FORUM_RECENT_POST_WIDGET_ID: '151bd179-dfe6-3ca3-399c-ee97957f93a2',
  FORUM_APP_PAGE_ID: '1489040e-001f-4631-55a9-2c29b4417126',
  CHAT_APP_DEF_ID: '14517e1a-3ff0-af98-408e-2bd6953c36a2',
  BLOG_APP_ID: '14bcded7-0066-7c35-14d7-466cb3f09103',
  PAID_PLANS_APP_ID: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  PAID_PLANS_SECTION_ID: 'membership_plan_picker_tpa'
};