var _objectDestructuringEmpty = require("@babel/runtime/helpers/objectDestructuringEmpty");

var _defineProperty = require("@babel/runtime/helpers/defineProperty");

class PermissionsCheckerV2 {
  constructor() {
    this.acl = new ResourceActionStorage(() => false);
    this.potentialPermissions = new ResourceActionStorage([]);
  }

  allow(resourceType, action, checkerBuilder) {
    const permissionTracker = permission => {
      this.potentialPermissions.update(resourceType, action, acc => [...acc, permission]);
    };

    const checker = new Checker(permissionTracker);
    this.acl.put(resourceType, action, checkerBuilder(checker));
  }

  getPotentialPermissions(resourceType, action) {
    return this.potentialPermissions.get(resourceType, action);
  }

  can(checkContext, resource, action) {
    const resourceType = resource.getResourceId();
    return this.acl.get(resourceType, action)(checkContext, resource);
  }

}

class ResourceActionStorage {
  constructor(defaultValue) {
    this.defaultValue = defaultValue;
    this.storage = {};
  }

  put(resourceType, action, value) {
    if (!this.storage[resourceType]) {
      this.storage[resourceType] = {};
    }

    if (!this.storage[resourceType][action]) {
      this.storage[resourceType][action] = value;
    }
  }

  get(resourceType, action) {
    this._initWithDefault(resourceType, action);

    return this.storage[resourceType][action];
  }

  update(resourceType, action, updateFn) {
    this._initWithDefault(resourceType, action);

    this.storage[resourceType][action] = updateFn(this.storage[resourceType][action]);
  }

  _initWithDefault(resourceType, action) {
    this.put(resourceType, action, this.defaultValue);
  }

}

class Checker {
  constructor(onIsPermittedCalled) {
    _defineProperty(this, "a", void 0);

    this.onIsPermittedCalled = onIsPermittedCalled;
  }

  and() {
    for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
      fns[_key] = arguments[_key];
    }

    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return fns.every(fn => fn.apply(null, args));
    };
  }

  or() {
    for (var _len3 = arguments.length, fns = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      fns[_key3] = arguments[_key3];
    }

    return function () {
      for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      return fns.some(fn => fn.apply(null, args));
    };
  }

  not(fn) {
    return function () {
      for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        args[_key5] = arguments[_key5];
      }

      return !fn.apply(null, args);
    };
  }

  isPermitted(expectedPermission) {
    this.onIsPermittedCalled(expectedPermission);
    return _ref => {
      let requestor = _ref.requestor;
      return requestor.permissions.includes(expectedPermission);
    };
  }

  isWriteProtectedCategory() {
    return (_ref2, resource) => {
      let categoryResolver = _ref2.categoryResolver;
      const category = categoryResolver(resource);
      return Boolean(category.isWriteProtected);
    };
  }

  isResourceOwner() {
    return (_ref3, resource) => {
      let requestor = _ref3.requestor;
      return resource.ownerSId && requestor && (resource.ownerSId === requestor.siteMemberId || resource.ownerSId === requestor.userId);
    };
  }

  isCommentsDisabledPost() {
    return (_ref4, resource) => {
      _objectDestructuringEmpty(_ref4);

      return Boolean(resource.isCommentsDisabled);
    };
  }

  hasAccessToCategory() {
    return (_ref5, resource) => {
      let categoryResolver = _ref5.categoryResolver,
          requestor = _ref5.requestor;
      const category = categoryResolver(resource);

      switch (true) {
        case category.type === 'private':
          return this._isPrivateCategoryAccessible(requestor, category);

        case category.type === 'membersOnly' || !category.type && category.isPrivate:
          return this._isMembersOnlyCategoryAccessible(requestor, category);

        case category.type === 'public' || !category.type && !category.isPrivate:
          return true;
      }

      return false;
    };
  }

  _isPrivateCategoryAccessible(requestor, privateCategory) {
    const categoryGroups = privateCategory.groups || [];
    const requestorGroups = requestor.groups || [];
    return categoryGroups.some(categoryGroup => requestorGroups.includes(categoryGroup));
  }

  _isMembersOnlyCategoryAccessible(requestor) {
    // TODO how do we check if it's not a Guest?
    // Introduce type?
    return requestor && requestor.type !== 'GUEST';
  }

}

module.exports = {
  PermissionsCheckerV2
};